import React from 'react'
import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material'
import { useField } from 'formik'

export type FormFieldTextProps = {
  name: string
  label: string | React.ReactElement
  placeholder?: string
  disabled?: boolean
  mandatory?: boolean
  multiLine?: boolean
  type?: 'text' | 'number'
  min?: number
  max?: number
}

export default function FormFieldText(props: FormFieldTextProps) {
  const { label, disabled, placeholder, mandatory, multiLine, type, min, max, ...rest } = props
  const [field, meta] = useField(props.name)
  const hasError = meta.touched && meta.error
  const inputType = type === 'number' ? 'number' : 'text'
  const inputMode = type === 'number' ? 'numeric' : undefined // Set input mode to 'numeric' only for 'number' type

  return (
    <FormControl variant="standard" error={!!hasError} disabled={disabled} fullWidth margin={'dense'}>
      <FormLabel htmlFor={'fld-' + field.name}>
        {label}
        {mandatory && '*'}
      </FormLabel>
      <OutlinedInput
        style={(multiLine && { height: 'auto' }) || { height: '32px' }}
        multiline={multiLine || false}
        rows={(multiLine && 3) || 1}
        {...field}
        type={inputType}
        inputProps={{ inputMode, min: min, max: max }}
        id={'fld-' + field.name}
        placeholder={placeholder}
        error={!!hasError}
        {...rest}
      />
      <FormHelperText>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}
